<div class="modal-body">
  <div class="mt-3 d-flex justify-content-between"
    style="background-color: #ebf2f7;padding: 10px 1rem;align-items: center;border-radius: 7px;">
    <div>
      <p class="boxlinks mb-1" style="font-weight:500; font-size: 13px;">Client: {{clientInfo.clientName + ' - ' +
        padZero(clientInfo.id, 3)}}
      </p>
      <p class="mb-0">Email: {{clientInfo.contactEmail}}</p>
    </div>
    <ng-container *ngIf="case.id > 0">
      <button class="btn btn-sm btn-outline-dark action-button-outline bg-white"
        (click)="closeCase(case.id, !case.active)">
        <i class="fa-regular" [ngClass]="case.active ? 'fa-xmark' : 'fa-check'"></i>
        {{ case.active ? ("Close Case" | translate) : ("Open Case" | translate) }}
      </button>
    </ng-container>
  </div>

  <div class="row mt-3">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label required">Case Title</label>
        <input type="text" class="no-margin form-control " id="caseName" name="clientId"
          [(ngModel)]="case.caseName" appSpecialCharsBlocker inputValidator [required]=true
          [min]=3 [max]=255>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group"><label class="control-label"><span class="required">Start Date</span></label>
        <p-calendar [(ngModel)]="case.startDate" name="startDate" placeholder="Select Start Date"
          [icon]="'fa-regular fa-calendar'" [showIcon]="true" appendTo="body" [showButtonBar]="true"></p-calendar>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group"><label class="control-label"><span class="required">Estimated End Date</span></label>
        <p-calendar [(ngModel)]="case.endDate" name="endDate" placeholder="Select End Date"
          [icon]="'fa-regular fa-calendar'" [showIcon]="true" appendTo="body" [showButtonBar]="true"></p-calendar>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group">
      <label><span class="required">Case Description</span></label>
      <textarea class="form-control" id="description" name="description" placeholder="Enter case details here"
        [(ngModel)]="case.description" rows="3" [maxlength]="1000" [max]="999" appSpecialCharsBlocker inputValidator></textarea>
    </div>
  </div>


  <div class="d-flex justify-content-between align-items-center mt-3">
    <h5 style="font-size: 1.1rem !important;"><i class="fa-regular fa-hand-holding-dollar mr-1"></i>Budget Settings</h5>
    <div class="form-check ms-3 text-end">
      <input class="form-check-input" type="checkbox" id="actionCheckbox" [(ngModel)]="isChecked"
        (change)="onCheckboxChange($event)">
      <label class="form-check-label" for="actionCheckbox">
        Copy from Previous Budget
      </label>
    </div>
  </div>
  <hr class="mt-0" />

  <div class="container mt-3">
    <ng-container *ngIf="billingType.length > 0">
      <div class="d-flex flex-wrap gap-2">
        <span class="m-0" style="font-size: 0.9rem; font-weight: 500;"><i
            class="fa-regular fa-chart-pie mr-1"></i>Choose how the budget will be calculated:</span>
        <div *ngFor="let chk of billingType" class="form-check">
          <input class="form-check-input" type="radio" id="{{chk.id}}" name="billingType" [value]="chk.id"
            [(ngModel)]="selectedBillingType" (change)="onSelectionChange(chk.id)">
          <label for="{{chk.id}}" class="form-check-label font-weight-600"> {{chk.type}} </label>
        </div>
      </div>
    </ng-container>

    <div class="d-flex align-items-center flex-wrap gap-2 mt-3">
      <span class="m-0" style="font-size: 0.9rem; font-weight: 500;"><i class="fa-regular fa-bell mr-1"></i>Notify me
        when the budget reaches:</span>
      <p-dropdown [options]="thresholds" name="drp-thresholds" optionLabel="name" [filter]="false" appendTo="body"
        [style]="{'width': '120px', 'min-width': '120px'}" [(ngModel)]="selectedThreshold" [showClear]="false"
        optionValue="value">
      </p-dropdown>
    </div>

    <div class="row">
      <div class="col-12 text-end">
        <button class="btn btn-sm btn-outline-dark action-button-outline bg-white" style="width: 120px;"
          (click)="addService()"><i class="fa-regular fa-plus mr-1"></i>Add Service</button>
      </div>
    </div>

    <div *ngFor="let service of case.services; let i = index"
      class="d-flex align-content-center justify-content-around mt-3">
      <div class="form-group">
        <label for="category" class="d-block"> Primary Service</label>
        <p-dropdown [options]="serviceCategoryDbList" name="drp-category{{i}}" optionLabel="categoryName"
          [filter]="true" [style]="{'width': 'calc(100vw - 85vw)'}" appendTo="body" [(ngModel)]="service.categoryId"
          (ngModelChange)="onCategoryChange($event,i)" filterBy="categoryName" [showClear]="false" dataKey="id"
          optionValue="id" placeholder="Select a Category">
        </p-dropdown>
      </div>
      <div class="form-group" *ngIf="tempServicesList.length > 0">
        <label for="service" class="d-block"> Sub-Service</label>
        <p-dropdown [options]="tempServicesList[i].servicesList" name="drp-service{{i}}" optionLabel="serviceName"
          [style]="{'width': 'calc(100vw - 78vw)'}" [filter]="true" appendTo="body" filterBy="serviceName"
          [showClear]="false" dataKey="id" optionValue="id" placeholder="Select a Service"
          [(ngModel)]="service.serviceId">
        </p-dropdown>
      </div>
      <div class="form-group">
        <label for="estimatedHours">Est. Hours</label>
        <input type="number" id="estimatedHours" class="form-control" [(ngModel)]="service.estimatedHours"
          style="width: 90px;" name="estimatedHours{{i}}">
      </div>
      <div class="form-group">
        <label for="estimatedCost">Est. Cost</label>
        <input type="number" id="estimatedCost" class="form-control" [(ngModel)]="service.estimatedCost"
          style="width: 90px;" name="estimatedCost{{i}}">
      </div>
      <button type="button" style="height: 25px;margin-top: 22px;" class="btn btn-sm btn-secondary"
        (click)="removeService(i)">
        <i class="fa fa-light fa-times"></i>
      </button>
    </div>

  </div>
</div>

<div class="modal-footer pr-4">
  <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()"
    [disabled]="case.id > 0 && !case.active">
    {{ case.id > 0 ? 'Update Case' : 'Save Case' }}
  </button>
  <!-- <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()" *ngIf="case.active">{{case.id > 0 ?
    'Update Case' : 'Save Case'}}</button>
    <button type="button" class="border-0 btn btn-primary btn-sm action-button" disabled = "true" (click)="onSubmit()" *ngIf="!case.active">{{case.id > 0 ?
    'Update Case' : 'Save Case'}}</button> -->
</div>
