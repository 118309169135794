<div class="container">

  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-dashboard"></i> Time Tracking Overview</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <div class="page-head-container-label">Month:</div>
      <p-calendar [(ngModel)]="selectedDate" view="month" dateFormat="MM, yy" [readonlyInput]="true" [showIcon]="true"
        [style.width]="'170px'" />
      <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-1"
        (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
    </div>
  </div>
  <div class="row">
    <!-- Summary Section -->
    <div class="col-md-4">
      <div class="card mb-3" style="height: 265px;">
        <div class="card-body">
          <h5 class="card-title card-heading">Overall Summary</h5>
          <ul class="list-group">
            <li class="list-group-item">Total Hours: {{ overview.summary.totalHours | number:'1.2-2' }}</li>
            <li class="list-group-item">Billable Hours: {{ overview.summary.billableHours | number:'1.2-2' }}</li>
            <li class="list-group-item">Non-Billable Hours: {{ overview.summary.nonBillableHours | number:'1.2-2' }}
            </li>
            <li class="list-group-item">Last Week Hours: {{ overview.summary.lastWeekHours | number:'1.2-2' }}</li>
            <li class="list-group-item">Current Week Hours: {{ overview.summary.currentWeekHours | number:'1.2-2' }}
            </li>
            <li class="list-group-item">Average Hours Per Task: {{ overview.summary.averageHoursPerTask |
              number:'1.2-2'
              }}</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Top Users Table -->
    <div class="col-md-8">
      <div class="card mb-3" style="height: 265px;">
        <div class="card-body">
          <h5 class="card-title card-heading">Top 5 Users by Total Hours</h5>
          <ng-container *ngIf="overview?.topUsers?.length > 0; else noUser">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Person Name</th>
                  <th>Total Hours</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of overview.topUsers">
                  <td>{{ user.userName }}</td>
                  <td>{{ user.totalHours | number:'1.2-2' }}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #noUser>
            <div class="no-track">
              <h5>Nothing tracked yet</h5>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">

    <!-- Daily Time Tracking Chart -->
    <div class="col-md-6">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title card-heading">Daily Time Tracking</h5>
          <p-chart type="line" [data]="dailyTimeTrackingData" [options]="chartOptions" height="200"></p-chart>
        </div>
      </div>
    </div>

    <!-- Weekly Time Tracking Chart -->
    <div class="col-md-3">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title card-heading">Weekly Time Tracking</h5>
          <p-chart type="line" [data]="weeklyTimeTrackingData" [options]="chartOptions" height="200"></p-chart>
        </div>
      </div>
    </div>

    <!-- Time Spent by Priority Donut Chart -->
    <div class="col-md-3">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title card-heading">Time Spent by Priority</h5>
          <ng-container *ngIf="timeSpentByPriorityData; else noPriorityData">
            <p-chart type="pie" [data]="timeSpentByPriorityData" [options]="chartOptionsDonut" height="200"></p-chart>
          </ng-container>

          <ng-template #noPriorityData>
            <div class="no-track" style="height: 200px;">
              <h5>Nothing tracked yet</h5>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

  </div>

  <!-- Client Cases Table -->
  <div class="row mt-4">
    <div class="col-md-12">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title card-heading">Top 5 Client Cases by Total Hours</h5>
          <ng-container *ngIf="overview?.clientsCases?.length > 0; else noClientsData">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Case Name</th>
                  <th>Total Hours</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let clientCase of overview.clientsCases">
                  <td>{{ clientCase.clientName }}</td>
                  <td>{{ clientCase.caseName }}</td>
                  <td>{{ clientCase.totalHours | number:'1.2-2' }}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>

          <ng-template #noClientsData>
            <div class="no-track" style="height: 200px;">
              <h5>Nothing tracked yet</h5>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <!-- Top Tasks Table -->
  <div class="row mt-4">
    <div class="col-md-12">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title card-heading">Top 5 Tasks by Total Hours</h5>
          <ng-container *ngIf="overview?.topTasks?.length > 0; else noTaskData">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Task Title</th>
                  <th>Total Hours</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let task of overview.topTasks">
                  <td>{{ task.taskTitle }}</td>
                  <td>{{ task.totalHours | number:'1.2-2' }}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #noTaskData>
            <div class="no-track" style="height: 200px;">
              <h5>Nothing tracked yet</h5>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
