import { Component, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';
import { CaseSetupComponent } from '../client-cases/case-setup/case-setup.component';
import { Result } from 'src/app/Models/Result';
import { padZero } from 'src/app/utils/utils';
import { ClientCase, CaseFilters } from 'src/app/Models/Case';
import { ClientDashboardPerformance } from 'src/app/Models/Clients';
import { LeadsConversions } from 'src/app/Models/LeadsConversions';
import { ClientDocumentsCounts } from 'src/app/Models/ClientDocuments';
import { ManageAssigneeComponent } from '../client-cases/manage-assignee/manage-assignee.component';
import { BillingSettingsSetupComponent } from '../client-cases/billing-settings-setup/billing-settings-setup.component';
import { CaseTotals } from 'src/app/Models/CaseTotals';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css'],
})
export class ClientProfileComponent {
  //#region refrence storing variables for model
  addLogModal: any;
  caseSetupHeader: string;
  cases: ClientCase[] = [];
  selectedCase: ClientCase = null;
  clientDashboardPerformance: ClientDashboardPerformance =
    new ClientDashboardPerformance();
  clientDocumentsInfo: ClientDocumentsCounts[] = [];
  clientInfo: any;
  freeResourcePercentage: number;
  taskCompletionRate: number;
  resourceUtilizationRate: number;
  clientTasksConversions: LeadsConversions[] = [];
  taskChartData: any;
  currentTaskCompletionRate: number = 0; // Initialize with 0
  currentResourceUtilizationRate: number = 0;
  currentFreeResourcePercentage: number = 0;
  clientId: number;
  public isLoading = false;
  completedSteps = {
    case: false,
    assignee: false,
    billing: false,
  };
  totals: CaseTotals = new CaseTotals();
  //#endregion

  // totalBudget: number = 25000; // Example total budget
  // totalCosts: number = 12000;
  // actualCost: number = 18000;
  // totalHours: number = 500;
  costForecast: number = 0;
  costToCompletion: number = 0;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private messageService: MessageService,
    private authService:AuthService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    // Using observable to catch the query parameters
    this.activatedRoute.queryParams.subscribe((params) => {
      const clientId = +params['id'];
      this.clientId = clientId;
      if (clientId > 0) {
        this.getCases(clientId).then((engs: ClientCase[]) => {
          this.cases = engs;
          this.getClientInfo(clientId).then((c) => {
            this.clientInfo = c;
            if (this.cases) {
              if (this.cases.find((x) => x.active === true)) {
                this.selectedCase = this.cases.find((x) => x.active === true);
              } else {
                this.selectedCase = this.cases[0];
              }
              this.getDataByCaseId();
            }
            //Code Modification doneby - hira
            this.isLoading = false;
            //Code Modification Needed - Salman
            //Get all data based on selectedCase.id
          });
        });
      }
    });
  }
  getDataByCaseId() {
    this.getPerformanceByCaseId(this.clientId, this.selectedCase.id).then(
      (resp) => {
        setTimeout(() => {
          this.currentTaskCompletionRate =
            this.taskCompletionRate > 0 ? this.taskCompletionRate : 0;
          this.currentResourceUtilizationRate =
            this.resourceUtilizationRate > 0 ? this.resourceUtilizationRate : 0;
          this.currentFreeResourcePercentage =
            this.freeResourcePercentage > 0 ? this.freeResourcePercentage : 0;
        }, 1);
      }
    );
    this.prepareCharts(this.clientId, this.selectedCase.id);
    this.getDocumentCountsByCaseId(this.clientId, this.selectedCase.id);
    this.getTotals().then((t) => {
      this.getCostForcast();
      this.getEstimatedCostToCompletion();
    });
  }
  getCases(clientId: number): Promise<ClientCase[]> {
    return new Promise((resolve, reject) => {
      const filters: CaseFilters = new CaseFilters();
      filters.clientId = clientId;

      this.apiService.getCases(filters).subscribe({
        next: (resp: Result) => {
          this.cases = [];
          if (resp.status === 'success') {
            resolve(resp.data);
          } else {
            resolve(null);
          }
        },
        error: (error) => {
          // Handle error
          reject(error);
          console.error('error:', error);
        },
      });
    });
  }

  getClientInfo(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.getClientById(id).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            resolve(resp.data);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
            resolve(null);
          }
        },
        error: (error) => {
          // Handle error
          reject(error);
          console.error('error:', error);
        },
      });
    });
  }

  getPerformanceByCaseId(id: number, caseId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.getPerformanceByCaseId(id, caseId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.clientDashboardPerformance = resp.data;
            this.freeResourcePercentage =
              this.clientDashboardPerformance.freeResourceRate * 100;
            this.taskCompletionRate =
              this.clientDashboardPerformance.taskCompletionRate * 100;
            this.resourceUtilizationRate =
              this.clientDashboardPerformance.resourceUtilizationRate * 100;
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
          console.error('error:', error);
        },
      });
    });
  }

  getDocumentCountsByCaseId(id: number, caseId: number) {
    this.apiService.getDocumentCountsByCaseId(id, caseId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.clientDocumentsInfo = resp.data;
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: resp.message,
            life: 3000,
          });
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }
  getTotals(): Promise<CaseTotals> {
    return new Promise<CaseTotals>((resolve, reject) => {
      this.apiService.getClientCaseTotals(this.selectedCase.id).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.totals = resp.data;
            if (this.totals) {
              // You can add any additional logic here if needed
            }

            resolve(this.totals); // Resolve the promise when the operation is successful
          } else {
            // Handle cases where the response status is not 'success'
            reject(new Error('Failed to get totals: ' + resp.status));
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
          reject(error); // Reject the promise on error
        },
      });
    });
  }
  //#region Cost Forcast

  getCostForcast() {
    // Assuming you have the following data:
    //totalCosts: number = 0 //- Current total costs
    //totalBudget: number = 0 //- Total budget
    let projectStartDate = new Date(this.selectedCase.startDate); //- Project start date
    let projectEndDate = new Date(this.selectedCase.endDate); //- Project end date
    let currentDate = new Date(); //- Current date

    // Calculate the number of days elapsed and total project duration
    let daysElapsed =
      (currentDate.getTime() - projectStartDate.getTime()) / (1000 * 3600 * 24);
    let totalProjectDuration =
      (projectEndDate.getTime() - projectStartDate.getTime()) /
      (1000 * 3600 * 24);

    // Calculate the daily spending rate
    let dailySpendingRate = this.totals.actualCost / daysElapsed;

    // Forecast the total cost by multiplying the daily spending rate by the total project duration
    this.costForecast = dailySpendingRate * totalProjectDuration;
  }

  getEstimatedCostToCompletion() {
    // Assuming you have the following data:
    // this.totalCosts: number - Current total costs
    // this.totalBudget: number - Total budget
    let remainingScope: number = 50; //- Estimated remaining scope of work (as a percentage)

    this.costToCompletion =
      this.totals.estimatedCost +
      (this.totals.estimatedCost * remainingScope) / 100;
  }
  get budgetUtilization(): number {
    return (this.totals.actualCost / this.totals.estimatedCost) * 100;
  }
  getProgressBarClass(progress) {
      if(this.hasPermission() === true){
        if (progress <= 25) {
          return 'low';
        } else if (progress <= 50) {
          return 'medium';
        } else if (progress <= 90) {
          return 'high';
        } else {
          return 'full';
        }
      }
      else{
        return null;
      }
  }
  //#endregion

  prepareCharts(id: number, caseId: number): void {
    this.apiService.getTaskConversionsByCaseId(id, caseId).subscribe({
      next: (resp: Result) => {
        this.clientTasksConversions = [];
        if (resp.status === 'success') {
          this.clientTasksConversions = resp.data;
          if (this.clientTasksConversions.length > 0) {
            const documentStyle = getComputedStyle(document.documentElement);

            let canvas = this.renderer.createElement('canvas');

            const gradient = canvas
              .getContext('2d')
              .createLinearGradient(244, 102, 255, 100);
            gradient.addColorStop(0, 'rgba(244, 102, 255, 0.2)');
            gradient.addColorStop(1, 'rgba(244, 102, 255, 0)');

            const gradient2 = canvas
              .getContext('2d')
              .createLinearGradient(100, 0, 50, 150);
            gradient2.addColorStop(0, 'rgba(20, 205, 255, 0.2)');
            gradient2.addColorStop(1, 'rgba(20, 205, 255, 0)');

            const gradient3 = canvas
              .getContext('2d')
              .createLinearGradient(100, 0, 50, 150);
            gradient3.addColorStop(0, 'rgba(255, 105, 20, 0.2)');
            gradient3.addColorStop(1, 'rgba(255, 105, 20, 0)');
            const gradient1 = canvas
              .getContext('2d')
              .createLinearGradient(100, 0, 50, 150);
            gradient1.addColorStop(0, 'rgba(50, 168, 82, 0.2)');
            gradient1.addColorStop(1, 'rgba(50, 168, 82, 0)');
            const MonthNames = [
              ...new Set(
                this.clientTasksConversions.map(
                  (l: LeadsConversions) => l.monthName
                )
              ),
            ];

            this.taskChartData = {
              labels: MonthNames,
              datasets: [
                {
                  type: 'line',
                  label: 'Completed',
                  borderColor: documentStyle.getPropertyValue('--blue-500'),
                  borderWidth: 2,
                  fill: false,
                  tension: 0.4,
                  data: this.clientTasksConversions
                    .filter((entry: any) => entry.label === 'Completed')
                    .map((entry: any) => entry.data),
                },
                {
                  type: 'line',
                  label: 'To Do',
                  borderColor: documentStyle.getPropertyValue('--pink-500'),
                  borderWidth: 2,
                  fill: false,
                  tension: 0.4,
                  data: this.clientTasksConversions
                    .filter((entry: any) => entry.label === 'Not Started')
                    .map((entry: any) => entry.data),
                },
                {
                  type: 'line',
                  label: 'In Progress',
                  borderColor: documentStyle.getPropertyValue('--green-500'),
                  borderWidth: 2,
                  fill: false,
                  tension: 0.4,
                  data: this.clientTasksConversions
                    .filter((entry: any) => entry.label === 'In Progress')
                    .map((entry: any) => entry.data),
                },
                {
                  type: 'line',
                  label: 'On Hold',
                  borderColor: documentStyle.getPropertyValue('--yellow-500'),
                  borderWidth: 2,
                  fill: false,
                  tension: 0.4,
                  backgroundColor: gradient3,
                  data: this.clientTasksConversions
                    .filter((entry: any) => entry.label === 'On Hold')
                    .map((entry: any) => entry.data),
                },
              ],
            };
          }
        } else {
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  viewCase() {
    this.router.navigate(['/manage-case'], {
      queryParams: { clientId: this.clientInfo.id }, //Cleint Id
    });
  }

  fileManagement() {
    this.router.navigate(['/document'], {
      queryParams: { clientId: this.clientInfo.id }, //Cleint Id
    });
  }

  addNewCase(item: any) {
    this.caseSetupHeader = 'Case Setup';
    let caseObj = new ClientCase();

    if (item) {
      this.caseSetupHeader = 'Case Setup';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(CaseSetupComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '60%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe(() => {
      caseObj = new ClientCase();
    });
  }

  padZero(id: number, minLength: number) {
    if (id) {
      return '#' + padZero(id, minLength);
    } else {
      return '';
    }
  }
  manageAssignee(item: any) {
    let caseObj = new ClientCase();

    if (item) {
      this.caseSetupHeader = 'Manage Assignee';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(ManageAssigneeComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '70%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe((result: any) => {
      if (result && result.success) {
        this.completedSteps.assignee = true; // Mark step as completed
      }
      this.loadData();
    });
  }
  addBillingSettings(item: any) {
    let caseObj = new ClientCase();

    if (item) {
      this.caseSetupHeader = 'Billing Settings';
      caseObj = item;
    }

    const modalRef = this.dialogService.open(BillingSettingsSetupComponent, {
      modal: true,
      showHeader: true,
      header: this.caseSetupHeader,
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        case: caseObj,
        clientInfo: this.clientInfo,
      },
    });
    modalRef.onClose.subscribe((result: any) => {
      if (result && result.success) {
        this.completedSteps.billing = true; // Mark step as completed
      }
      this.loadData();
    });
  }
  onCaseIdSelected(caseobj: any) {
    this.selectedCase = caseobj;
    this.getDataByCaseId();
  }
  hasPermission(): boolean {
    if(this.authService.hasPermission('budget-costing'))
      {
        return true;
      }
      else{
        return false;
      }
  }
}
