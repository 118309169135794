<div class="modal-body">
  <div class="container mt-3">
    <ng-container *ngIf="case.billableTypeId !== 1">
      <div class="alert alert-warning p-1" role="alert">
        <i class="fa fa-regular fa-circle-info text-dark mr-1 ml-1"></i>
        <span>You cannot set rates per hour for this case if the billing type in Case Billing Settings is not User-Based
        </span>
      </div>
    </ng-container>
    <div class="d-flex align-items-center mb-3" style="background: aliceblue;padding: 5px 10px;">
      <i class="fa-regular fa-sitemap mr-2 fw-bold text-secondary"></i>
      <strong class="mb-0 me-2">Team:</strong>
      <p-dropdown appendTo="body" [options]="teams" name="teams" optionLabel="name" [filter]="true"
        [ngModel]="selectedTeamId" (onChange)="onTeamSelect($event)" filterBy="name" [showClear]="false" dataKey="id"
        optionValue="id" placeholder="Select a team">
      </p-dropdown>
    </div>
    <div class="row align-items-center">
      <div class="col-md-5">
        <h5>Available Users</h5>
        <p-listbox [options]="availableUsers" [(ngModel)]="selectedAvailableUserId" name="lst-availableUsers"
          [multiple]="false" filterBy="userName" appendTo="body" optionLabel="name" optionValue="id"
          [style]="{'height': '300px', 'width': '100%'}" [listStyle]="{'max-height': '100%'}"
          [class]="'available-users'">

          <ng-template let-user pTemplate="item">
            <div class="d-flex align-items-center justify-content-between w-100">
              <div>
                <p class="mb-1 fw-bolder">{{ user.name }}</p>
                <p class="mb-1 fs-11px"><span class="fw-600 ">Team:</span> {{ user.teamName }}</p>
                <p class="mb-1 fs-11px"><span class="fw-600 ">Job Title:</span> {{ user.jobTitle }}</p>
              </div>
              <button class="btn btn-sm btn-outline-dark action-button-outline bg-white btn-list"
                (click)="assignUser(user.id)"><i class="fa-solid fa-arrow-right-long"></i></button>
            </div>
          </ng-template>

        </p-listbox>
      </div>
      <div class="col-md-2">
        <div class="d-flex flex-column justify-content-center align-items-center ml-3 mr-3">
          <button class="btn btn-sm btn-outline-dark action-button-outline bg-white mb-2" title="Assign All Users"
            style="width: 50px;" (click)="assignAllUsers()"><i class="fa-regular fa-angles-right"></i></button>
          <button class="btn btn-sm btn-outline-dark action-button-outline bg-white mb-2" title="Assign User"
            style="width: 50px;" (click)="assignUser()"><i class="fa-regular fa-angle-right"></i></button>

          <button class="btn btn-sm btn-outline-dark action-button-outline bg-white mb-2" title="Unassign User"
            style="width: 50px;" (click)="unAssignUser()"><i class="fa-regular fa-angle-left"></i></button>
          <button class="btn btn-sm btn-outline-dark action-button-outline bg-white mb-2" title="Unassign All Users"
            style="width: 50px;" (click)="unAssignAllUsers()"><i class="fa-regular fa-angles-left"></i></button>
        </div>
      </div>
      <div class="col-md-5">
        <h5>Assigned Users / Rates Per Hour</h5>
        <p-listbox [options]="this.caseAssigneRates.caseAssignees" [(ngModel)]="selectedAssignedUserId"
          name="lst-caseAssigneRates.caseAssignees" [multiple]="false" filterBy="userName" appendTo="body"
          optionLabel="name" optionValue="id" [style]="{'height': '300px', 'width': '100%'}"
          [listStyle]="{'max-height': '100%'}" [class]="'assigned-users'">
          <ng-template let-user pTemplate="item">
            <div class="d-flex align-items-center w-100">
              <button class="btn btn-sm btn-outline-dark action-button-outline bg-white btn-list me-3"
                (click)="unAssignUser(user.id)"><i class="fa-solid fa-arrow-left-long"></i></button>
              <div class="d-flex align-items-center justify-content-between w-100">
                <div>
                  <p class="mb-1 fw-bolder">{{ user.name }}</p>
                  <p class="mb-1 fs-11px"><span class="fw-600 ">Team:</span> {{ user.teamName }}</p>
                  <p class="mb-1 fs-11px"><span class="fw-600 ">Job Title:</span> {{ user.jobTitle }}</p>
                </div>
                <div style="width: 100px;">
                  <p class="mb-1">Rates/Hour:</p>
                  <input type="number" id="inputRatesPerHours{{user.id}}" [disabled]="case.billableTypeId !== 1"
                    class="form-control w-100 text-center" [(ngModel)]="user.ratesPerHour" name="inputRatesPerHours{{user.id}}"
                    (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
                    (keypress)="$event.stopPropagation()">
                </div>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    </div>
  </div>

</div>

<div class="modal-footer mt-4 pr-2">
  <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
    (click)="closeModal()">Cancel</button>
  <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()">Update
    Assignees</button>
</div>
