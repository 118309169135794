<div class="modal-body">
  <div class="table-responsive activity-tables mt-2" style="height: calc(100vh - 450px);margin-bottom: 1rem;">
    <table class="table table-hover">
      <thead class="sticky-header thead-light">
        <tr>
          <th style="width: 20px; padding: 0.75rem;"> #</th>
          <th style="width: 300px;">{{"Title" | translate}}</th>
          <th>{{"Client" | translate}}</th>
          <th>{{"Case" | translate}}</th>
          <th>{{"User" | translate}}</th>
          <th>{{"Status" | translate}}</th>
          <th>{{"Start Time" | translate}}</th>
          <th>{{"End Time" | translate}}</th>
          <th>{{"Total" | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let task of timeEntryDetails let rowIndex = index">
          <td style="vertical-align: sub;">
            <div class="">{{ rowIndex + 1 }}</div>
          </td>

          <td>
            <div>{{ task.title + ' - ' + padZero(task.taskId,3) }}</div>
          </td>
          <td class="leadname-td"> <div class="leadname" style="max-width: 100px;">{{task.clientName}}</div></td>
          <td class="leadname-td"> <div class="leadname" style="max-width: 100px;" >{{task.caseName}}</div></td>
          <td>{{task.name}}</td>
          <td>
            <span class="badge rounded-pill" [style.color]="task.foreColor" [style.backgroundColor]="task.bgColor">{{
              task.status | translate }}</span>
          </td>
          <td>{{task.startTime ? (task.startTime | date:'short') : '-'}}</td>
          <td>{{ task.endTime ? (task.endTime|date:'short'):'-'}}</td>
          <td>
            {{ (task.startTime?task.startTime:null) | timeDifference:(task.endTime?task.endTime:null) }}
          </td>
          <!-- <td>
            <div class="ellipsis" style="max-width: 130px; font-weight: 500">{{ !item.caseName ?
              '-':item.caseName | translate }}</div>
          </td> -->

        </tr>
      </tbody>
    </table>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="close()">
    OK
  </button>
</div>
