<div class="container">
    <div class="page-head-container">
      <h4 class="page-title"><i class="fa-regular fa-users"></i> {{ "Roles" | translate }}</h4>
      <!-- Filter Section -->
      <div class="d-flex align-items-center">
        <!-- [hasPermission]="{ access: 'ADD_ROLE', action: 'disable' }" -->
        <button [hasPermission]="{ access: 'manage-roles', action: 'disable' }" 
          class="btn btn-primary action-button text-nowrap ms-5" (click)="addNewRole()"><i class="fa-light fa-plus"></i>
          {{ "Add New Role" | translate }}</button>
      </div>
    </div>


    <div class="table-responsive" style="height: calc(100vh - 200px);"
      *ngIf="roles != null && roles.length > 0; else !isLoading ? noData : loader">
      <table class="table table-hover">
        <thead>
          <tr class="sticky-header">
          <tr>
            <th scope="col">Role Name</th>
            <th scope="col">Description</th>
            <th scope="col">Total Permissions</th>
            <th scope="col">System Predefined Role</th>
            <th scope="col">Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let role of roles; let i = index">
            <td>{{ role.roleName }}</td>
            <td>{{ role.description || 'N/A' }}</td>
            <td [ngClass]="{ 'clickable': role.totalPermissions > 0 }"
              (click)="role.totalPermissions > 0 && rolePermissions(role)">
            {{ role.totalPermissions }}
          </td>
            <td>
              <span class="badge status bg fs-12px" [class]="role.isSystemRole && role.isSystemRole === true ? 'not-started' : 'inactive'">{{role.isSystemRole === true ? 'YES' : 'NO' }}</span>
            </td>
            <td><span class="bg status badge fs-12px" [class]="role.active ? 'active' : 'inactive'">{{ role.active ?
                'Active' : 'Inactive' }}</span></td>
            <td>
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" style="height: 25px;"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ "Options" | translate }}
                </button>
                <div class="dropdown-menu">
                  <button class="dropdown-item table-action-btn mb-2" [hasPermission]="{ access: 'manage-roles', action: 'disable' }"  (click)="managePermissions(role)">
                    <i class="fa-regular fa-eye mr-1"></i>
                    {{"Manage Role Permissions" | translate}}</button>
                  <button class="dropdown-item table-action-btn mb-2" (click)="editRole(role)" [hasPermission]="{ access: 'manage-roles', action: 'disable' }"  *ngIf="!role.isSystemRole">
                    <i class="fa-regular fa-pencil mr-1"></i>
                    {{"Edit Role Information" | translate}}</button>
                  <!-- <a class="dropdown-item table-action-btn mb-2" (click)="disableUser(user)">
                    <i class="fa-regular fa-user-slash mr-1"></i>
                    {{"Disable User" | translate}}</a> -->
                  <button class="dropdown-item table-action-btn" (click)="deleteRole(role)" [hasPermission]="{ access: 'manage-roles', action: 'disable' }"   *ngIf="!role.isSystemRole">
                    <i class="fa-regular fa-trash mr-1"></i>
                    {{"Delete Role" | translate}}</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ng-template #loader>
      <app-project-loading-indicator height="calc(100vh - 205px)" />
    </ng-template>

    <ng-template #noData>
      <app-nodatafound message="{{'No role created yet.' | translate }}" height="calc(100vh - 205px)"></app-nodatafound>
    </ng-template>

