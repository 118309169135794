<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-calendar"></i> Monthly Calendar <span
        style="background: #cedfd0;padding: 2px 6px;border: 1px solid #ccc;border-radius: 6px;margin-left: 0.5rem;color: #000000;">
        {{ viewDate | calendarDate:(calenderView + 'ViewTitle'):'en' }}</span></h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <div class="page-head-container-label">Month:</div>
      <p-calendar [(ngModel)]="viewDate" view="month" dateFormat="MM, yy" [readonlyInput]="true" [showIcon]="true"
        [style.width]="'170px'" (onMonthChange)="dateChange()" />
      <div class="page-head-container-label">User:</div>
      <p-dropdown appendTo="body" name="name" [options]="usersList" [(ngModel)]="selectedAssignees" optionLabel="name"
        placeholder="Select User Name" (onChange)="onUserSelect($event)"></p-dropdown>
      <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-2"
        (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
    </div>
  </div>

  <div [ngSwitch]="calenderView" style="
  height: calc(100vh - 200px);
  overflow: auto; border: 1px solid #ccc;">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="tasks"
      [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
      (eventClicked)="openTaskDetailView('Clicked', $event.event)" [cellTemplate]="monthViewCustomCellTemplate"
      [openDayEventsTemplate]="openDayEventsTemplate" [headerTemplate]="defaultTemplate" [excludeDays]="excludeDays">
    </mwl-calendar-month-view>

    <div style="
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 0rem 1rem 1rem;
  display: none;
">
      <p class="m-0"><strong>Total Tasks:</strong> 0</p>
      <p class="m-0"><strong>Total Hours:</strong> 0.00</p>
    </div>
  </div>

  <ng-template #defaultTemplate let-days="days" let-locale="locale"
    let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate">
    <div class="cal-cell-row cal-header" role="row">
      <div class="cal-cell" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
        [ngStyle]="{'background-color': day.isWeekend ? 'rgb(245 245 245 / 60%)' : 'unset'}"
        [class.cal-past]="day.isPast" [class.cal-today]="day.isToday" [class.cal-future]="day.isFuture"
        [class.cal-weekend]="day.isWeekend" [ngClass]="day.cssClass" tabindex="0" role="columnheader">
        {{ day.date | date : 'EEE' : locale }}
      </div>
      <!-- <div class="cal-cell">
      Week Total
    </div> -->
    </div>
  </ng-template>

  <ng-template #openDayEventsTemplate>
  </ng-template>

  <ng-template #monthViewCustomCellTemplate let-day="day" let-locale="">

    <div class="cal-cell-top" [ngStyle]="{'background-color': day.isWeekend ? 'rgb(245 245 245 / 60%)' : 'unset'}">
      <span class="cal-day-number {{isCurrentDay(day.date) ? 'isSameDay' : ''}}" style="color: #000000;opacity: 0.7;">
        {{ day.date.toLocaleDateString(locale, { day: 'numeric' }) }}
      </span>
    </div>
    <a *ngFor="let event of day.events" class="event-container-view"
      [ngStyle]="{ 'background-color': event?.dbValue?.bgColor, 'color': event?.dbValue?.iconColor}"
      (click)="openTaskDetailView('View', event?.dbValue?.dailyTaskDetails)">

      <div class="event-container-view-container">
        <span pTooltip="{{event?.dbValue?.organizer?.split(';')[1] }}" tooltipPosition="top">
          <i [ngClass]="event?.dbValue?.icon" [ngStyle]="{ 'color': event?.dbValue?.iconColor }"></i>
          <span class="ml-1 text-dark">{{event.title}}</span>
        </span>
      </div>
    </a>

  </ng-template>


  <ng-template #modalContent let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Event action occurred</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Action:
        <pre>{{ modalData?.action }}</pre>
      </div>
      <div>
        Event:
        <pre>{{ modalData?.event | json }}</pre>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="close()">
        OK
      </button>
    </div>
  </ng-template>

</div>
