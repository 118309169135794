<div class="modal-body me-3">
  <form class="mt-3">
    <div class="form-row m-0">
      <div class="form-group col-md-6">
        <label for="clientName"><i class="far fa-user"></i> Client Name <span class="text-danger">*</span></label>
        <input type="text" class="form-control mt-1" name="clientName" id="clientName" placeholder="Enter client name"
          [(ngModel)]="client.clientName" appSpecialCharsBlocker inputValidator [required]="true"
          [min]=3 [max]=255 >

      </div>
      <div class="form-group col-md-6">
        <label for="contactName"><i class="far fa-address-card"></i> Contact Name</label>
        <input type="text" class="form-control mt-1" appSpecialCharsBlocker inputValidator name="contactName" id="contactName"
          placeholder="Enter contact name" [(ngModel)]="client.contactName">
      </div>
    </div>
    <div class="form-row m-0">
      <div class="form-group col-md-6">
        <label for="contactEmail "><i class="far fa-envelope"></i> <span class="required">Contact Email</span></label>
        <input type="email" class="form-control mt-1" name="contactEmail" id="contactEmail"
          placeholder="Enter contact email" [(ngModel)]="client.contactEmail" emailValidator required>
      </div>
      <div class="form-group col-md-6">
        <label for="contactPhone"><i class="far fa-phone"></i> Contact Phone</label>
        <input type="tel" class="form-control mt-1" name="contactPhone" id="contactPhone"
          placeholder="Enter contact phone" [(ngModel)]="client.contactPhone" phoneValidator appOnlyNumbers>

      </div>
    </div>
    <div class="form-group">
      <label for="addressLine1"><i class="far fa-map-marker-alt"></i> <span class="required">Address Line 1 </span></label>
      <input type="text" class="form-control mt-1" name="addressLine1" id="addressLine1"
        placeholder="Enter address line 1" appSpecialCharsBlocker inputValidator [(ngModel)]="client.addressLine1">
    </div>
    <div class="form-group">
      <label for="addressLine2"><i class="far fa-map-marker-alt"></i> Address Line 2</label>
      <input type="text" class="form-control mt-1" name="addressLine2" id="addressLine2"
        placeholder="Enter address line 2" appSpecialCharsBlocker inputValidator [(ngModel)]="client.addressLine2">
    </div>

    <div class="form-row m-0 justify-content-between">
      <div class="form-group">
        <label for="country" class="d-block"><i class="far fa-globe"></i> <span class="required">Country</span></label>
        <p-dropdown appendTo="body" [options]="countriesList" name="country" optionLabel="name" [filter]="true"
          [ngModel]="client.countryId" (ngModelChange)="onCountryChange($event)" filterBy="name" [showClear]="false"
          dataKey="id" optionValue="id" placeholder="Select a Country">
        </p-dropdown>
      </div>
      <div class="form-group">
        <label for="state" class="d-block"><i class="far fa-map-marked-alt"></i> <span class="required">State</span></label>
        <p-dropdown appendTo="body" [options]="statesList" name="stateId" [ngModel]="client.stateId"
          (ngModelChange)="onStateChange($event)" optionLabel="name" [filter]="true" filterBy="name" [showClear]="false"
          dataKey="id" optionValue="id" placeholder="Select a State">
        </p-dropdown>
      </div>
      <div class="form-group">
        <label for="city" class="d-block"><i class="far fa-city"></i> <span class="required">City</span></label>
        <p-dropdown appendTo="body" [options]="citiesList" name="cityId" [ngModel]="client.cityId"
          (ngModelChange)="onCityChange($event)" optionLabel="name" [filter]="true" filterBy="name" [showClear]="false"
          dataKey="id" optionValue="id" placeholder="Select a city">
        </p-dropdown>
      </div>
      <div class="form-group">
        <label for="postalCode"><i class="far fa-mail-bulk"></i> Zip Code</label>
        <input type="text" class="form-control mt-1" name="zipCode" id="zipCode" placeholder="Enter postal code"
          [(ngModel)]="client.zipCode" appOnlyNumbers>
      </div>
    </div>

    <div class="form-group">
      <label for="clientType"><i class="far fa-users"></i> <span class="required">Client Type</span></label>
      <p-dropdown appendTo="body" name="clientType" [options]="clientTypes" [(ngModel)]="selectedClientType"
        optionLabel="type" placeholder="Select Client Type" />
    </div>
    <div class="form-group">
      <label for="industryType"><i class="far fa-industry"></i> <span class="required">Industry Type</span></label>
      <p-dropdown appendTo="body" name="industryType" [options]="industryTypes" [(ngModel)]="selectedIndustryType"
        optionLabel="type" placeholder="Select Industry Type" />
    </div>
  </form>
</div>

<div class="modal-footer mt-3 mb-3 pr-4">
  <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
    (click)="modalClose()">Cancel</button>
  <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()"
    [disabled]="this.isDataSaving">{{client.id>0? 'Update
    Client' : 'Create Client'}}</button>
</div>
