<div class="container-fluid">
  <div class="bg-body-whitish" *ngIf="cases != null && cases.length > 0;
    else !isLoading ? noData : loader">
    <div class="filter-container">
      <app-client-share-filters [clientId]="clientId" (caseSelected)="onCaseIdSelected($event)" />
    </div>

    <div class="row mt-4">

      <!-- Client Information Card -->
      <div class="col-md-6">
        <div class="card box-charts mb-4 h-100">
          <div class="card-body custom-card">
            <div class="client-card client-info flex-grow-1">
              <div class="client-img"
                style="background-image: url(https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png);">
              </div>
              <div class="client-datials flex-grow-1">
                <h5 class=""></h5>
                <h5 class="" *ngIf="selectedCase">
                  <div class="badge rounded-pill fw-600" style="color: whitesmoke;background-color: cadetblue;padding-top: 6px;">Case : {{
                    selectedCase.caseName| translate }}</div>
                </h5>
                <h3 class=" mt-n1 mb-1" style="font-size: 18px;">{{padZero(clientInfo?.id, 3) + ' - '
                  +clientInfo?.clientName}}</h3>
                <p class="mb-1" style="font-size: 12px;">
                  {{clientInfo?.clientType}} <b> | </b> {{clientInfo?.industryType}}
                </p>
                <p class="mb-1" style="font-size: 11px;">{{clientInfo?.city}} <b> | </b> {{clientInfo?.country}} </p>
                <p class="mt-1" style="font-size: 11px;">
                  <a class="align-items-center" [pTooltip]="tooltipAddress"><i class="fa-regular fa-home"></i> Show
                    Address</a>
                  <ng-template #tooltipAddress let-data="clientInfo">
                    <div class="flex align-items-center">
                      <p class="performer-tooltip">
                        {{ clientInfo?.addressLine1 ?? '' }}
                        <br>
                        {{ clientInfo?.addressLine2 ?? '' }}
                      </p>
                    </div>
                  </ng-template>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <hr class="hr-transparent bg-white-transparent-2 mt-0" style="margin-bottom: 10px;">
                <div class="row" style="margin-bottom: 10px;">
                  <div class="col-8 col-lg-7">
                    <div class="mt-1">
                      <div style="color:#189ee3 ;">Contact Person</div>
                      <div class="font-weight-600">{{clientInfo?.contactName}}</div>
                      <div class="mt-3" style="color: #189ee3 ;">Phone</div>
                      <div class="font-weight-600">{{clientInfo?.contactPhone}}</div>
                    </div>
                  </div>
                  <div class="col-4 col-lg-5">
                    <div style="color: #189ee3 ;">Registered Date:</div>
                    <div class="font-weight-600" style="white-space: nowrap;">{{clientInfo?.dateCreated | date}}</div>
                    <div class="mt-3" style="color: #189ee3 ;">Email</div>
                    <div class="font-weight-600 ellipsis" tooltipPosition="top" [pTooltip]="clientInfo?.contactEmail">
                      {{clientInfo?.contactEmail}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Case Performance Card (Grid Layout) -->
      <div class="col-md-3">
        <div class="card box-charts mb-4 h-100">
          <h5 class="card-heading-1">
            Client Documents
          </h5>
          <ng-container
            *ngIf="clientDocumentsInfo && clientDocumentsInfo.length > 0; else noclientDocumentsInfo"></ng-container>
          <ul class="list-group borderless" *ngFor="let item of clientDocumentsInfo;">
            <li class="list-group-item borderless d-flex justify-content-between align-items-center"
              [pTooltip]="tooltipContentDocs">
              {{item.mainCategoryName}}
              <span class="badge bg-success rounded-pill" *ngIf="item.hasDocuments">+</span>
              <span class="badge bg-danger rounded-pill" *ngIf="!item.hasDocuments">x</span>
            </li>
            <ng-template #tooltipContentDocs let-data="item">
              <div class="flex align-items-center">
                <p class="performer-tooltip">
                  Sub Categories: {{ item.subCategoriesWithCount }}
                </p>
              </div>
            </ng-template>
          </ul>

          <ng-template #noclientDocumentsInfo>
            <li class="list-group-item text-center m-4">
              <i class="far fa-file fa-2x text-muted"></i>
              <p class="mt-2">No documents found.</p>
            </li>
          </ng-template>
        </div>
      </div>
      <!-- Case Performance Card (Grid Layout) -->
      <div class="col-md-3">
        <div class="card box-charts mb-4 h-100">
          <h5 class="card-heading-1">
            Case Performance
          </h5>
          <div class="card-body">
            <!-- Task Completion Rate -->
            <div class="mb-4">
              <h6>Task Completion Rate </h6>
              <div class="progress">
                <div class="progress-bar bg-info" role="progressbar"
                  [style.width.%]="currentTaskCompletionRate>0?currentTaskCompletionRate:0"
                  [attr.aria-valuenow]="currentTaskCompletionRate" aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-text">{{currentTaskCompletionRate | number:'1.2-2' }}%</div>
                </div>
              </div>
            </div>
            <!-- Resource Utilization -->
            <div class="mb-4">
              <h6>Resource Utilization Rate</h6>
              <div class="progress">
                <div class="progress-bar bg-warning" role="progressbar"
                  [style.width.%]="currentResourceUtilizationRate>0?currentResourceUtilizationRate:0"
                  [attr.aria-valuenow]="currentResourceUtilizationRate" aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-text">{{
                    currentResourceUtilizationRate | number:'1.2-2' }}%</div>
                </div>
              </div>
            </div>
            <!-- Client Satisfaction Score -->
            <div class="mb-4">
              <h6>Free Resource Rate</h6>
              <div class="progress">
                <div class="progress-bar bg-danger" role="progressbar"
                  [style.width.%]="currentFreeResourcePercentage>0?currentFreeResourcePercentage:0"
                  [attr.aria-valuenow]="currentFreeResourcePercentage" aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-text">{{
                    currentFreeResourcePercentage | number:'1.2-2' }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-12 mt-3 stats-container">
        <div class="card">
          <h5 class="card-heading-1">
            <i class="fa-solid fa-chart-line"></i> Case Statistics
          </h5>
          <div class="card-body statistics-details d-flex align-items-center justify-content-between mb-4">
            <div class="box-shape">
              <p class="statistics-title fw-600">Budget</p>
              <div class="d-flex flex-row">
                <div class="me-5"><span class="text-gray-light font-weight-500 small text-uppercase"> Spent
                  </span>
                  <div class="font-weight-500 font-size-5 text-dark">
                    {{ hasPermission() ? (totals.actualCost | currency: 'Rs ') : 0 }} </div>
                </div>
                <div><span class="text-gray-light font-weight-500 small text-uppercase"> Target </span>
                  <div class="font-weight-500 font-size-5 text-dark">
                    {{ hasPermission() ? (totals.estimatedCost | currency: 'Rs ') : 0 }}
                    </div>
                </div>
              </div>

              <div class="d-flex flex-column mt-2">
                <div class="progress">
                  <div class="progress-bar" role="progressbar">
                    <div class="progress-fill" [style.width.%]="budgetUtilization > 0?budgetUtilization : 0"
                      [class]="getProgressBarClass(budgetUtilization)" [attr.aria-valuenow]="budgetUtilization"
                      aria-valuemin="0" aria-valuemax="100">
                    </div>
                    <div class="progress-text"> {{ hasPermission() ? (budgetUtilization | number:'1.2-2') : 0 }} %</div>
                  </div>
                </div>

              </div>
            </div>

            <div class="box-shape">
              <p class="statistics-title fw-600">Total Cost</p>
              <div class="d-flex flex-row">
                <div class="me-5">
                  <span class="text-gray-light font-weight-500 small text-uppercase"> Current </span>
                  <div class="font-weight-500 font-size-5 text-dark">
                    {{ hasPermission() ? (totals.actualCost | currency: 'Rs ') : 0 }} </div>
                </div>
                <div>
                  <span class="text-gray-light font-weight-500 small text-uppercase"> Forecast </span>
                  <div class="font-weight-500 font-size-5 text-dark"
                    [pTooltip]="costForecast > totals.estimatedCost ? 'Higher than target' : 'Lower than target'"> Rs
                    <span
                      [ngClass]="{'text-danger': costForecast > totals.estimatedCost, 'text-success': costForecast < totals.estimatedCost}">{{
                      costForecast > totals.estimatedCost ? '▲' : '▼' }} </span>
                      {{ hasPermission() ? (costForecast | currency: 'Rs ') : 0 }}
                  </div>
                </div>
              </div>
              <p style="font-size: 11px;margin: 0;position: absolute;bottom: -14px;left: 0px;"><i
                  class="fa-light fa-circle-info mr-1"></i>Forecast is based on
                current spending trends</p>
            </div>

            <div class="box-shape">
              <p class="statistics-title fw-600">Hours Spent</p>
              <div class="d-flex flex-row justify-content-between">
                <div>
                  <span class="text-gray-light font-weight-500 small text-uppercase"> Total </span>
                  <div class="font-weight-500 font-size-5 text-dark">
                    {{ hasPermission() ? ((totals?.billableHours +
                    totals?.nonBillableHours).toFixed(2)) : 0 }} H </div>
                </div>
                <div><span class="text-gray-light font-weight-500 small text-uppercase"> Billable
                  </span>
                  <div class="font-weight-500 font-size-5 text-dark">
                    {{ hasPermission() ? (totals?.billableHours?.toFixed(2)) : 0 }} H </div>
                </div>
                <div>
                  <span class="text-gray-light font-weight-500 small text-uppercase"> Non-Billable </span>
                  <div class="font-weight-500 font-size-5 text-dark"> {{ hasPermission() ? (totals?.nonBillableHours?.toFixed(2)) : 0 }} H </div>
                </div>
              </div>
            </div>

            <div class="box-shape me-0 w-50" [pTooltip]="tooltipContent1" style="border-right: 0px;">
              <ng-template #tooltipContent1 let-data="performer">
                <div class="flex align-items-center">
                  <p class="performer-tooltip">
                    {{clientDashboardPerformance.busyResourcesName}}
                  </p>
                </div>
              </ng-template>
              <p class="statistics-title fw-600">Resource Utilization</p>
              <h3 class="rate-percentage">
                <span class="text-medium" style="color: #327fa8;">
                  {{clientDashboardPerformance.resourceUtilization}}</span>
              </h3>
            </div>

            <ng-template #tooltipContent2 let-data="performer">
              <div class="flex align-items-center">
                <p class="performer-tooltip">
                  {{clientDashboardPerformance.freeResourcesName}}
                </p>
              </div>
            </ng-template>
          </div>
        </div>

      </div>
      <!-- Analytics Card (Line Chart) -->
      <div class="col-md-6 mt-3">
        <div class="card box-charts mb-4">
          <h5 class="card-heading-1">
            Client Task Metrics
          </h5>
          <div class="box-charts" style="height: 300px;">
            <div class="p-24">
              <p-chart type="line" [data]="taskChartData" height="250px" width="100%"></p-chart>
            </div>
          </div>
        </div>
      </div>

      <!-- Analytics Card (Line Chart) -->
      <div class="col-md-6 mt-3">
        <div class="card box-charts mb-4">
          <h5 class="card-heading-1">
            Client Statistics
          </h5>
          <div class="card-body">
            <img src="https://apexcharts.com/wp-content/uploads/2018/05/basic-bar-chart-column-chart.svg" width="500px">
          </div>
        </div>
      </div>

    </div>
  </div>

  <ng-template #loader>
    <app-project-loading-indicator />
  </ng-template>
  <ng-template #noData>
    <app-incompletesetup [clientInfo]=clientInfo [clientId]=clientId [cases]="cases" message=""
      height="calc(100vh - 340px)"></app-incompletesetup>
  </ng-template>
</div>
