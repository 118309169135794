<div class="container-fluid">
  <div class="bg-body-whitish">
    <div class="filter-container">
      <app-client-share-filters [clientId]="clientId" [type]="'ClientDocument'"
        (caseSelected)="onCaseIdSelected($event)" />
    </div>
    <div class="d-flex justify-content-between align-items-end mb-2">
      <h5 class="m-0">
        {{ "Manage Client Documents" | translate }}
      </h5>

    </div>
    <ng-container *ngIf="message">
      <div class="alert alert-warning p-1" role="alert">
        <i class="fa fa-regular fa-circle-info text-dark mr-1 ml-1"></i>
        <span>This case is closed but you can add its documents.
        </span>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-md-3">
        <div class="card" style="overflow-y: auto; height:calc(100vh - 170px);">
          <p-tree [value]="files" class="w-full md:w-30rem" selectionMode="single"
            (onNodeSelect)="onNodeSelect($event)">
            <ng-template let-node pTemplate="default">
              <div class="d-flex align-items-center" (click)="toggleNode(node)">
                <i class="fa-light" [ngClass]="{
              'fa-folder-open': node.data.Id === this.subCategoryId,
              'fa-folder': (node.data.Id !== this.subCategoryId)
            }"></i>
                <span class="ml-2">{{ node.label }}</span>
              </div>
            </ng-template>
            <ng-template let-file pTemplate="url">
              <a [href]="file.data" target="_blank" rel="noopener noreferrer" class="text-700 hover:text-primary">
                {{ file.label }}
              </a>
            </ng-template>
          </p-tree>
        </div>
      </div>
      <div class="card col-md-6" *ngIf="path">
        <div class="card-header">
          <div class="d-flex justify-content-between align-items-end mb-2">
            <h5 class="m-0">
              <i class="fa-light fa-folder-open"></i> {{ path | translate }}
            </h5>
            <div class="d-flex align-items-center justify-content-end">
              <button class="btn btn-primary action-button me-2 text-nowrap mt-2" (click)="addNewDocument(null)"><i
                  class="fa-light fa-plus"></i> {{ "Add New Document" | translate }}</button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive activity-tables" style="overflow-y: auto; height: calc(100vh - 200px);" *ngIf="clientDocuments != null && clientDocuments.length > 0;
        else !isLoading ? noData : loader">
            <table class="table table-hover">
              <thead class="sticky-header thead-light">
                <tr>
                  <th style="width: 200px;">{{ "Name" | translate }}</th>
                  <th>{{ "Date Created" | translate }}</th>
                  <th>{{ "Size" | translate }}</th>

                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of clientDocuments;let rowIndex = index">
                  <tr (click)="showdetails(item)">
                    <td class="leadname-td">
                      <div class="d-flex align-items-center leadname-container">
                        <div class="leadname d-flex align-items-center file-name">
                          <div class="file-icon mr-2" [ngClass]="item.fileExt"></div>{{ item.documentName}}
                        </div>

                      </div>
                    </td>

                    <td>
                      <div class="ellipsis" style="max-width: 85px; font-weight: 500;">{{ item.dateCreated | date }}
                      </div>
                    </td>
                    <td>
                      <div class="ellipsis" style="max-width: 130px; font-weight: 500;">{{ item.fileSize }} Kb</div>
                    </td>


                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <ng-template #loader>
            <app-project-loading-indicator />
          </ng-template>

          <ng-template #noData>
            <app-nodatafound message="{{'No document has been uploaded yet.' | translate }}"
              height="calc(100vh - 285px)"></app-nodatafound>
          </ng-template>
        </div>

      </div>
      <div class="col-md-3" *ngIf="clientDetailDocuments">
        <div class="card" style="overflow-y: auto; height: calc(150vh - 350px);">
          <div class="d-flex flex-column justify-content-center align-items-center h-50">
            <div class="file-icon mr-2 mb-3" [ngClass]="clientDetailDocuments.fileExt"></div>

            <div class="d-flex flex-column align-items-center">
              <div style="font-size: 1rem;margin-bottom: .8rem;padding: 0;">
                <span>{{this.clientDetailDocuments.documentName}}</span>
              </div>
              <span>{{this.clientDetailDocuments.description}}</span>
              <span style="font-size: .8rem;">Created: {{ this.clientDetailDocuments.dateCreated | date }}</span>
              <span style="font-size: .8rem;">Size: {{this.clientDetailDocuments.fileSize}} KB</span>
            </div>


            <div class="d-flex justify-content-around align-items-center w-90 mt-4">
              <div class="file-info-btn-container" (click)="deleteDocument(this.clientDetailDocuments)">
                <button class="border-0">
                  <i class="far fa-trash-alt file-info-btn" title="Delete document"></i>
                  <br>
                  <span style="font-size:0.75rem;">Delete</span></button>
              </div>
              <div class="file-info-btn-container mr-2">
                <a [href]="clientDetailDocuments.fileLocation" class="text-center">
                  <i class="fas fa-file-download file-info-btn align-items-center" title="Download document"></i>
                  <br>
                  <span style="font-size:0.75rem;">Download</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
