<!-- time-sheet.component.html -->
<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-timer"></i> {{ "Time Sheet" | translate }} <span
        style="background: #cedfd0;padding: 2px 6px;border: 1px solid #ccc;border-radius: 6px;margin-left: 0.5rem;color: #000000;">
        {{ startDate | date: 'MMM dd' }} - {{ endDate | date: 'MMM dd, yyyy' }}</span></h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <div class="page-head-container-label">Week:</div>
      <div class="btn-group me-3">
        <div class="btn btn-sm btn-outline-dark action-button-outline" (click)="previousWeek()">
          Previous
        </div>
        <div class="btn btn-sm btn-outline-dark action-button-outline" (click)="setCurrentWeekDates()"
          style="background: #f5f5f5cc;">
          Today
        </div>
        <div class="btn btn-sm btn-outline-dark action-button-outline" (click)="nextWeek()">
          Next
        </div>
      </div>
      <div class="page-head-container-label">User:</div>
      <p-dropdown appendTo="body" name="name" [options]="usersList" [(ngModel)]="selectedAssignees" optionLabel="name"
        placeholder="Select User Name" (onChange)="onUserSelect($event)"></p-dropdown>
      <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-1"
        (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
    </div>
  </div>

  <div class="timesheet-container">
    <div style="display: flex; flex-direction: column; justify-content: center; margin: 1rem 0rem 1rem 1rem;">
      <p class="m-0"><strong>Total Tasks:</strong> {{ timeSheetData.length }}</p>
      <p class="m-0"><strong>Total Hours:</strong> {{ weekTotalHours | number:'1.2-2' }}</p>
    </div>

    <div style="
  border: 1px solid #ccc;">

      <div class="table-responsive time-sheet-table" style="overflow-y: auto; height: calc(100vh - 254px);" *ngIf="
timeSheetData != null && timeSheetData.length > 0;
else !isLoading ? noData : loader
">
        <table class="table table-hover">
          <thead>
            <tr class="sticky-header">
              <th style="width: 40px;"></th>
              <th>ID</th>
              <th style="width: 300px; text-align: left;">Title</th>
              <th>Estimated Hours</th>
              <th *ngFor="let day of getDaysRange(startDate, endDate)">{{ day }}</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let entry of timeSheetData">
              <tr>
                <td class="text-center">
                  <button type="button" class="btn m-0 p-0" style="color: #333;"
                    (click)="entry.expanded = !entry.taskDetails ? false : !entry.expanded">
                    <i [ngClass]="entry.expanded ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-right'"></i>
                  </button>
                </td>
                <td>{{ entry.taskId }}</td>
                <td style="text-align: left;">
                  <p class="mb-1"> <a class="task-title m-0" style="text-transform: capitalize;"
                      (click)="entry.expanded = !entry.taskDetails ? false : !entry.expanded">{{
                      entry.title + ' - ' + padZero(entry.taskId,3) }}</a> </p>
                  <p class="mb-1 fs-11px" style="text-transform: capitalize;"><b>Client: </b>{{ !entry.clientName ?
                    '-':entry.clientName |translate }}</p>
                  <p class="mb-0 fs-11px" style="text-transform: capitalize;"><b>Case: </b> {{ !entry.caseName ? '-' : entry.caseName |
                    translate }} </p>
                </td>
                <td>{{ entry.estimatedHours | number:'1.2-2' }}</td>
                <td *ngFor="let day of getDaysRange(startDate, endDate)">
                  {{ entry.actualHoursByDate[day] | number:'1.2-2' }}</td>
                <td>{{ getTotalHours(entry) | number:'1.2-2' }}</td>
              </tr>
              <ng-container *ngIf="entry.expanded">
                <tr>
                  <td colspan="20" style="padding: 10px;background: #fbfbfb;">
                    <div style="border: 1px solid #ccc;padding: 6px;border-radius: 6px;">
                      <table class="nested-table">
                        <thead>
                          <tr>
                            <th>User</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Total Hours</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let item of entry.taskDetails">
                            <tr>
                              <td>{{ item.assigneeName }}</td>
                              <td>{{item.startTime ? (item.startTime | date:'MMMM dd, yyyy hh:mm a') : '-'}}</td>
                              <td>{{ item.endTime ? (item.endTime|date:'MMMM dd, yyyy hh:mm a'):'-'}}</td>
                              <td>
                                {{ (item.startTime?item.startTime:null) |
                                timeDifference:(item.endTime?item.endTime:null)
                                }}
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>

              </ng-container>
            </ng-container>
          </tbody>
          <tfoot class="sticky-footer">
            <tr>
              <td colspan="3">Total</td>
              <td>{{ getTotalEstimatedHours() | number:'1.2-2' }}</td>
              <td *ngFor="let day of getDaysRange(startDate, endDate)">
                {{ getTotalActualHoursByDate(day) | number:'1.2-2' }}
              </td>
              <td>{{ getGrandTotalHours() | number:'1.2-2' }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <ng-template #loader>
    <app-project-loading-indicator />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="No data available for this week." height="calc(100vh - 340px)"></app-nodatafound>
  </ng-template>

</div>
