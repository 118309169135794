<div class="modal-body me-3 p-2">
    <form class="mt-3">
        <button #taskDropButton type="button" class="btn btn-sm form-action-menu-btn" (click)="loc.toggle($event)">
            <i class="fa-regular fas fa-map-pin mr-1"></i> {{ "Set Location" | translate }}
          </button>
          <p-overlayPanel #loc appendTo="body" [dismissable]="false">
            <div style="width: 750px; padding: 5px 10px 5px 10px;">
            <google-map
            height="350px"
            width="100%"
            [center]="center"
            [zoom]="zoom"
            (mapClick)="mapClicked($event)">
            <map-marker [position]="center"></map-marker>
          </google-map>
          <div class="card coordinates mt-2">
            <p><b>Latitude:</b> {{ center.lat }}
            <br><b>Longitude:</b> {{ center.lng }}</p>
          </div>
          <button type="button" class="btn btn-sm btn-primary right" (click)="closeDropdown()" style="font-size: 11px;">
            <i class="fa-solid fa-check"></i> Done
          </button>
            </div>
            </p-overlayPanel>
      <div class="form-row m-0">
        <div class="form-group col-md-12">
          <label for="clientName"><i class="far fa-map-marker-alt"></i> Latitude <span class="text-danger">*</span></label>
          <input type="text" class="form-control mt-1" name="clientName" id="clientName" placeholder="Enter latitude"
            [(ngModel)]="center.lat" 
            [min]=3 [max]=30 >
  
        </div>
      </div>
      <div class="form-row m-0">
        <div class="form-group col-md-12">
            <label for="contactName"><i class="far fa-map-marker-alt"></i> Longitude <span class="text-danger">*</span></label>
            <input type="text" class="form-control mt-1"  name="contactName" id="contactName"
              placeholder="Enter longitude" [(ngModel)]="center.lng">
        </div>
      </div>
      <div class="form-row m-0">
        <div class="form-group col-md-12">
          <label for="contactEmail"><i class="far fa-location-arrow"></i> Radius <span class="text-danger">*</span></label>
          <input type="email" class="form-control mt-1" name="contactEmail" id="contactEmail"
            placeholder="Enter radius" [(ngModel)]="clientLocation.radius">
            <span>KM (decimal)</span>
        </div>
      </div>
     </form>
  </div>
  
  <div class="modal-footer mt-3 mb-3 pr-4">
    <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
      (click)="modalClose()">Cancel</button>
    <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()"
      >Save</button>
  </div>
  


 