import { Component, ViewChild } from '@angular/core';
import {
  Task,
  TaskCheckInState,
  TaskStatus,
  TasksFilter,
  TimeEntryReq,
} from 'src/app/Models/Task';
import { Result } from 'src/app/Models/Result';
import { padZero } from 'src/app/utils/utils';
import { TaskSetupComponent } from '../task-setup/task-setup.component';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import {
  trigger,
  state,
  style,
  transition,
  group,
  query,
  animateChild,
  animate,
} from '@angular/animations';
import { TaskViewComponent } from '../task-view/task-view.component';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalVariables } from 'src/environments/environment';
import { HrmsPluginSettings } from 'src/app/Models/HrmsPluginSettings';
import { AttendanceConfirmationComponent } from '../../common/attendance-confirmation/attendance-confirmation.component';
import { CheckInRequestDetail } from 'src/app/Models/CheckInRequestDetail';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(450px)' })),
      transition('left => right', [
        group([query('@childSlide', animateChild()), animate('300ms ease-in')]),
      ]),
      transition('right => left', [
        group([
          query('@childSlide', animateChild()),
          animate('300ms ease-out'),
        ]),
      ]),
    ]),
  ],
})
export class TaskListComponent {
  @ViewChild(AttendanceConfirmationComponent)
  attendanceConfirmationModal: AttendanceConfirmationComponent;
  markAttendanceDetails: CheckInRequestDetail;
  currentUserId: number = 0;
  statusTypeId: number = 2; // Incase of lead component
  slideState = 'right';
  //task: Task = new Task();
  taskStaus: TaskStatus = new TaskStatus();
  taskFilter: TasksFilter = new TasksFilter();
  taskStatusList: TaskStatus[] = [];
  tasks: Task[] = [];
  totalCount: number = 0;
  currentPage = 1;
  pageSize = 50;
  displayedPages: number[] = [];
  fromDate: any = new Date(new Date().setMonth(new Date().getMonth() - 1));
  toDate: any = new Date(); //"12-31-2022";
  isLoading = false;
  totalPages: number = 0;
  pageHeading: string;
  addLogModal: any;
  modelHeader: string;
  clientType: string;
  //#endregion

  //Task Related
  currentTask: Task;
  totalTasks: number = 0;
  inProgressTasks: number = 0;
  completedTasks: number = 0;
  onHoldTasks: number = 0;
  notStartedTasks: number = 0;
  taskStatusChartData: any;
  taskChartData: any;
  taskCheckIn:TaskCheckInState;
  //#region HRMS Plugin

  hrmsPluginSettings: HrmsPluginSettings;

  //#endregion

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private calendar: NgbCalendar,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private sharedService: SharedService
  ) {
    this.currentUserId = this.authService.currentUserValue.id;
    this.toDate = this.calendar.getToday();
    this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'm', 1);

    this.loadHrmsPluginConfig();
    this.taskCheckIn = new TaskCheckInState();
    this.sharedService.onTaskStart.subscribe((task: Task) => {
      if (task) {
        this.resetTaskRunningState();
      }
    });

    this.sharedService.onTaskStop.subscribe((id: Number) => {
      let index = this.tasks.findIndex((task) => task.id === id);
      if (index !== -1) {
        // var task = this.tasks[index];
        // this.initiateTaskStop(task);
        this.tasks[index].isStarted = false;
      }
    });
  }

  ngOnInit(): void {
    this.getTasks();
  }

  toggleFilter() {
    this.slideState = this.slideState === 'right' ? 'left' : 'right';
  }

  onHideFilterRequest() {
    this.toggleFilter();
  }

  onClientTypeFilterChange(clientType: string) {
    // this.taskFilter.clientType = clientType;
    this.clientType = clientType;
    // this.getTasks();
  }

  onSearchRequest(filters: TasksFilter) {
    this.toggleFilter();
    this.taskFilter = filters;
    this.currentPage = this.taskFilter.pageNumber;
    this.getTasks();
  }

  addEditTask(item: Task) {
    this.modelHeader = 'New Task';
    let task = new Task();

    if (item) {
      this.modelHeader = 'Edit Task';
      task = item;
      task.checklists = [];
    }

    const tsModalRef = this.dialogService.open(TaskSetupComponent, {
      modal: true,
      showHeader: true,
      header: this.modelHeader,
      width: '60%',
      contentStyle: { overflow: 'hidden', padding: '0px' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        task: task,
      },
    });
    tsModalRef?.onClose.subscribe(() => {
      task = new Task();
      this.getTasks();
    });
  }

  viewTask(item: Task) {
    this.modelHeader = 'Task Details';
    let task = item;
    task = item;
    if (item) {
      task = item;
      task.checklists = [];
    }
    const modalRef = this.dialogService.open(TaskViewComponent, {
      modal: true,
      showHeader: true,
      header: this.modelHeader,
      width: '90%',
      contentStyle: { overflow: 'hidden', padding: '0px' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        task: task,
      },
    });

    modalRef.onClose.subscribe(() => {
      task = new Task();
      this.getTasks();
    });
  }

  async loadHrmsPluginConfig() {
    this.apiService.getHrmsPluginConfig().subscribe({
      next: (data: any) => {
        if (data) {
          if (data.isActive) {
            if (data.settings) {
              const obj = JSON.parse(data.settings);
              this.hrmsPluginSettings = obj;
            }
          }
        }
      },
      error: (error) => {},
    });
  }

  getTasks() {
    this.isLoading = true;
    this.taskFilter.pageNumber = this.currentPage;
    this.taskFilter.pageSize = this.pageSize;
    this.apiService.getAllTasks(this.taskFilter).subscribe({
      next: (resp: Result) => {
        this.isLoading = false;
        this.tasks = [];
        if (resp.status === 'success') {
          this.tasks = resp.data;

          if (this.tasks?.length > 0) {
            this.totalCount = this.tasks[0].totalRecords;

            this.tasks.forEach((t) => {
              if (t.assignee) {
                try {
                  const assigneeStrings = t.assignee.split(', ');
                  t.taskAssignee = assigneeStrings.map((assigneeStr) => {
                    const [
                      idStr,
                      name,
                      imageUrl,
                      estimatedHours,
                      ratesPerHour,
                    ] = assigneeStr.split(';');
                    return {
                      Id: parseInt(idStr, 10),
                      Name: name.trim(),
                      ProfilePictureUrl: imageUrl.trim(),
                      EstimatedHours: Number(estimatedHours.trim()),
                      RatesPerHour: ratesPerHour
                        ? Number(ratesPerHour.trim())
                        : null,
                    };
                  });

                  t.firstAssignee = t.assignee.split(',')[0];
                  const UserObjects = t.assignee.split(',');
                  t.assigneeIds = UserObjects.map((user) =>
                    parseInt(user.split(';')[0])
                  );
                } catch (e) {
                  console.error(e);
                }
              }
              if (t.tagIdsString) {
                t.tagIds = t.tagIdsString
                  .split(',')
                  .map((tagId) => parseInt(tagId));
              }
            });

            this.generateDisplayedPages();
          }
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: resp.message,
            life: 3000,
          });
        }
      },
    });
  }

  initiateTaskStart(task: Task) {
    // Check if the current user is assigned to the task
    if (!task.assigneeIds.includes(this.currentUserId)) {
      return null;
    }
    this.startTask(task, false);
    // If hrmsPluginSettings is not defined, start the task without marking attendance
    if (!this.hrmsPluginSettings) {
      return this.startTask(task, false);
    }

    const { allowMarkAttendance, autoMarkAttendance, allowUserChoice } =
      this.hrmsPluginSettings;

    // Check attendance options
    if (allowMarkAttendance) {
      var type = 'CheckIn';
      if (autoMarkAttendance) {
        this.taskCheckIn.automark = true;
        this.taskCheckIn.clientId = task.clientId;
        this.sharedService.triggerTaskCheckIn(this.taskCheckIn);
        // Auto mark attendance logic can be added here if needed
        this.startTask(task, true);
      } else if (allowUserChoice) {
        this.currentTask = task;
        this.taskCheckIn.automark = false;
        this.taskCheckIn.clientId = task.clientId;
        this.sharedService.triggerTaskCheckIn(this.taskCheckIn);

        // this.attendanceConfirmationModal.onConfirmed.subscribe((event) => {
        //   if (event.confirm) {
        //     this.markAttendance(event.type);
        //   }
        // });

        return null; // Exit after confirmation
      }
    }

    // Default to starting the task without marking attendance


    return null;
  }

  startTask(task: Task, markAttendance: boolean): Promise<Result> {
    const timeEntry = new TimeEntryReq();

    return new Promise<Result>((resolve, reject) => {
      if (task.taskStatusId === 4) {
        this.confirmationService.confirm({
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          acceptIcon: 'none',
          rejectIcon: 'none',
          rejectButtonStyleClass: 'p-button-text',
          message:
            'Are you sure you want to initiate the task? This action will change its status from <b>On Hold</b> to <b>In Progress</b>',
          accept: () => {
            timeEntry.statusId = 2; // Change status to 2 when accepted
            this.executeStartTask(task, timeEntry, resolve, reject);
          },
          reject: () => {},
        });
      } else {
        timeEntry.statusId = task.taskStatusId;
        this.executeStartTask(task, timeEntry, resolve, reject);
      }
    });
  }

  private executeStartTask(
    task: Task,
    timeEntry: TimeEntryReq,
    resolve: (value: Result) => void,
    reject: (reason?: any) => void
  ): void {
    if (timeEntry.statusId && timeEntry.statusId > 0) {
      this.resetTaskRunningState();
      timeEntry.taskId = task.id;
      timeEntry.userId = this.authService.currentUserValue.id;

      this.apiService.startTask(timeEntry).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            task.status = 'In Progress';
            task.taskStatusId = 2;
            task.taskStatusBgColor = '#ffc107';
            this.sharedService.triggerTaskStart(task);
            task.isStarted = true;
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          resolve(resp);
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    } else {
      reject(new Error('Invalid status ID.'));
    }
  }

  initiateTaskStop(task: Task) {
    // Check if the current user is assigned to the task
    if (!task.assigneeIds.includes(this.currentUserId)) {
      return null;
    }

    // If hrmsPluginSettings is not defined, start the task without marking attendance
    if (!this.hrmsPluginSettings) {
      return this.stopTask(task, false);
    }
    this.stopTask(task, false);
    const { allowMarkAttendance, autoMarkAttendance, allowUserChoice } =
      this.hrmsPluginSettings;

    // Check attendance options
    if (allowMarkAttendance) {
      var type = 'Check Out';
      if (autoMarkAttendance) {
        this.taskCheckIn.automark = true;
        this.taskCheckIn.clientId = task.clientId;
        this.sharedService.triggerTaskCheckOut(this.taskCheckIn);
        // Auto mark attendance logic can be added here if needed
        this.stopTask(task, true);
      } else if (allowUserChoice) {
        this.currentTask = task;
        this.taskCheckIn.automark = false;
        this.taskCheckIn.clientId = task.clientId;
        this.sharedService.triggerTaskCheckOut(this.taskCheckIn);
        // this.attendanceConfirmationModal.message =
        //   "You're about to stop this task. Would you like to check out for the day?";
        // this.attendanceConfirmationModal.showModal('check-out');
        return null; // Exit after confirmation
      }
    }

    // Default to starting the task without marking attendance
   // this.stopTask(task, false);

    return null;
  }

  stopTask(task: Task, markAttendance: boolean) {
    const timeEntry = new TimeEntryReq();
    timeEntry.taskId = task.id;
    timeEntry.userId = this.authService.currentUserValue.id;

    return new Promise<void>((resolve, reject) => {
      this.apiService.stopTask(timeEntry).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.sharedService.triggerTaskStop(task.id);

            task.isStarted = false;
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    });
  }


  resetTaskRunningState() {
    this.tasks.map((x) => (x.isStarted = false));
  }

  onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.getTasks();
  }
  calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }
  generateDisplayedPages(): void {
    this.totalPages = Math.ceil(this.totalCount / this.pageSize);
    const numberOfDisplayedPages = 3; // Number of page number buttons to display
    const halfTotalPagesToShow = Math.floor(numberOfDisplayedPages / 2);
    // Logic to generate an array of displayed page numbers
    const startPage = Math.max(1, this.currentPage - halfTotalPagesToShow);
    const endPage = Math.min(
      this.totalPages,
      this.currentPage + halfTotalPagesToShow
    );

    this.displayedPages = Array.from(
      { length: endPage + 1 - startPage },
      (_, index) => startPage + index
    );
  }

  onPageSizeChange(): void {
    this.currentPage = 1;
    this.getTasks();
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
  deleteTask(id, isStarted) {
    if (isStarted === true) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You cannot delete this task until you stop this task.',
        life: 3000,
      });
    } else {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        rejectButtonStyleClass: 'p-button-text',
        message: 'Do you want to delete this task?',
        accept: () => {
          this.apiService.deleteTask(id).subscribe({
            next: (resp: Result) => {
              if (resp.status === 'success') {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: resp.message,
                  life: 3000,
                });
                this.getTasks();
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: resp.message,
                  life: 3000,
                });
              }
            },
            error: (error) => {
              // Handle error
              console.error('error:', error);
            },
          });
        },
        reject: () => {},
      });
    }
  }

  getStatusClass(task: Task) {
    if (task.taskStatusId === 1) {
      return 'not-started';
    } else if (task.taskStatusId === 2) {
      return 'inprogress';
    } else if (task.taskStatusId === 3) {
      return 'completed';
    } else if (task.taskStatusId === 4) {
      return 'onhold';
    }

    return '';
  }

  getCompletionStatus(item: Task): string {
    const now = new Date();
    const dueDate = new Date(item.dueDate);
    const completionDate = item.completionDate
      ? new Date(item.completionDate)
      : null;

    if (item.taskStatusId === 3) {
      // Task is completed; check if it was completed on time or early
      if (completionDate && completionDate < dueDate) {
        return 'Completed Early';
      } else if (completionDate && completionDate > dueDate) {
        return 'Overdue';
      } else {
        return 'On Track';
      }
    } else {
      // Task is not completed; check if it is on track or overdue
      return dueDate >= now ? 'On Track' : 'Overdue';
    }
  }
}
