<div class="container">
    <div class="page-head-container">
      <h4 class="page-title"><i class="fa-regular fa-map-marked-alt"></i> {{ "Manage Locations" | translate }}</h4>
      <!-- Filter Section -->
      <div class="d-flex align-items-center">
        <button class="btn btn-primary action-button " (click)="updateLocation(null)" [hasPermission]="{ access: 'manage-client-location', action: 'hide' }" ><i
            class="fa-light fa-plus"></i>
          {{ "Add New Location" | translate }}</button>
      </div>
    </div>
    <div class="table-responsive time-sheet-table" style="overflow-y: auto; height: calc(100vh - 200px);" 
    *ngIf="clientLocations?.length > 0; else noDataOrLoader">
    <table class="table table-hover">
        <thead>
            <tr class="sticky-header">
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Radius</th>
                <th>{{ "Actions" | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let loc of clientLocations">
                <td>{{ loc.latitude }}</td>
                <td>{{ loc.longitude }}</td>
                <td>{{ loc.radius }} Km</td>
                <td class="">
                    <div class="btn-group">
                      <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        {{ "Options" | translate }}
                      </button>
                      <div class="dropdown-menu">
                        <button class="dropdown-item table-action-btn"  (click)="updateLocation(loc)"><i
                            class="fa-regular fa-edit mr-1"></i>
                          {{"Edit Client Location" | translate}}</button>
                        <button class="dropdown-item table-action-btn"  (click)="deleteClientLocationById(loc.id)"><i
                            class="fa-regular fa-trash mr-1"></i>
                          {{"Delete Client Location" | translate}}</button>
                      </div>
                    </div>
                  </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #noDataOrLoader>
    <div *ngIf="isLoading; else noData">
        <app-project-loading-indicator />
    </div>
    <ng-template #noData>
        <!-- No data content here -->
        <app-nodatafound message="No locations are added against this client." height="calc(100vh - 340px)"></app-nodatafound>

    </ng-template>
</ng-template>
    
    </div>
    
