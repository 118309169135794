<!-- Loading Animation Section -->
<div *ngIf="isLoading">
  <div class="modal-body text-center d-flex justify-content-center align-items-center flex-column" style="height: 265px;">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="mt-3">Getting Task data, please wait...</div>
  </div>
</div>

<!-- Processing Animation Section -->
<div *ngIf="isProcessing">
  <div class="modal-body text-center d-flex justify-content-center align-items-center flex-column" style="height: 265px;">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Processing...</span>
    </div>
    <div class="mt-3">Uploading tasks in bulk, please wait...</div>
  </div>
</div>

<!-- Bulk task template Section -->
<div *ngIf="!isProcessing && !isLoading">
  <div class="p-3">
    <div>
      <div class="d-flex flex-column align-items-start">
        <h6 class="mb-2">
          <i class="fas fa-info text-primary me-1"></i> Select & Create Bulk Tasks
        </h6>
        <p>By choosing the tasks from the template, you can create tasks in bulk.</p>
      </div> 

      <!-- Description for Start Date and End Date -->
      <div class="d-flex align-items-center mt-2">

      <div class="row mt-3">
        <p class="text-muted">Choose the date when you want to start and end the tasks.</p>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Start Date</label>
            <p-calendar [(ngModel)]="selectedTemplate.startDate" name="startDate" placeholder="Select Start Date"
              [icon]="'fa-regular fa-calendar'" [showIcon]="true" appendTo="body" [showButtonBar]="true"></p-calendar>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="control-label">Estimated End Date</label>
            <p-calendar [(ngModel)]="selectedTemplate.endDate" name="endDate" placeholder="Select End Date"
              [icon]="'fa-regular fa-calendar'" [showIcon]="true" appendTo="body" [showButtonBar]="true"></p-calendar>
          </div>
        </div>

      </div>
    </div>
      <hr>
      <!-- Description above Task Template and Services -->
      <div class="d-flex align-items-center mt-2">
        <ng-container *ngIf="!isLoading; else userLoader">
          <div class="p-3">
            <p class="text-muted">Select the template from which you want to create and also select the services
              associated with bulk tasks.</p>
            <div class="d-flex align-items-center mb-3">
              <div class="form-group d-flex align-items-center me-4">
                <label for="templateSelect" class="fw-600 me-2">Template Type:</label>
                <p-dropdown id="templateSelect" [options]="systemTemplate" [(ngModel)]="selectedTemplate"
                  optionLabel="name" placeholder="Select a template" (onChange)="filterByTemplate()">
                </p-dropdown>
              </div>
              <div class="form-group d-flex align-items-center">
                <label for="serviceSelect" class="fw-600 me-2">Services:</label>
                <p-dropdown id="serviceSelect" [options]="serviceCategoryDbList" [(ngModel)]="selectedServices"
                  optionLabel="categoryName" placeholder="Select a service" (onChange)="filterByService()">
                </p-dropdown>
              </div>
            </div>
            <hr>
            <div class="table-responsive" style="height: 270px; width: 955px; border: 2px solid #ccc; border-radius: 4px; overflow-y: scroll;">
              <table class="table table-hover">
                <thead class="sticky-header">
                  <tr>
                    <th>
                      <p-checkbox [(ngModel)]="selectAll" [binary]="true" (onChange)="toggleAllCheckboxes()"></p-checkbox>
                    </th>
                    <th>Title</th>
                    <th style="width: 370px; text-align: left;">Description</th>
                    <th style="text-align: left;">Acceptance Criteria</th>
                    <th>Category</th>
                    <th>service</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of taskList">
                    <td>
                      <p-checkbox [(ngModel)]="user.selected" 
                                  [binary]="true" 
                                  [value]="user.selected"
                                  [disabled]="!isServiceInCase(user.serviceId)">
                      </p-checkbox>
                    </td>
                    <td>{{ user.title }}</td>
                    <td>
                      <p class="mb-0 fw-500">{{ user.description }}</p>
                    </td>
                    <td>{{ user.acceptanceCriteria }}</td>
                    <td>{{ user.categoryName }}</td>
                    <td>{{ user.serviceName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
        <ng-template #userLoader>
          <div class="p-3">
            <app-project-loading-indicator [height]="'270px'" [width]="'600px'" [message]="'Fetching templates from task track'" />
          </div>
        </ng-template>
      </div>
      <ng-container *ngIf="message && message !== null">
        <div class="alert alert-warning p-1" role="alert">
          <i class="fa fa-regular fa-circle-info text-dark mr-1 ml-1"></i>
          <span>{{message}}
          </span>
        </div>
      </ng-container>
      <!-- <div class="form-check mt-3">
        <p-checkbox [(ngModel)]="selectedTemplate.isCreatedInBulk" label="Allow all tasks to be created in bulk."
          [binary]="true" (onChange)="toggleTaskOptions()"></p-checkbox>
      </div> -->
    </div>
    <hr>
  </div>
  <div class="modal-footer p-3 d-flex justify-content-end">
    <button type="button" class="btn btn-sm btn-secondary me-2" (click)="modalClose()">Close</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="saveSetting()">Save Settings</button>
  </div>
</div>
