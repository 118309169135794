import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {
  show = false;
  message = '';

  @Output() retry = new EventEmitter<void>();

  constructor() { }

  showToast(message: string) {
    this.message = message;
    this.show = true;
  }

  retryLocation() {
    this.show = false;
    this.retry.emit();
  }
  hideToast() {
    this.show = false;
  }
}
