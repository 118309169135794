<div class="container">
  <div class="page-head-container">
    <h4 class="page-title"><i class="fa-regular fa-list-check"></i> {{ "Manage Case" | translate }}</h4>
    <!-- Filter Section -->
    <div class="d-flex align-items-center">
      <button class="btn btn-primary action-button " (click)="addNewCase(null)" [hasPermission]="{ access: 'addUpdateCase', action: 'hide' }" ><i
          class="fa-light fa-plus"></i>
        {{ "Create New Case" | translate }}</button>
    </div>
  </div>

  <div class="table-responsive time-sheet-table" style="overflow-y: auto; height: calc(100vh - 200px);" *ngIf="
cases != null && cases.length > 0;
else !isLoading ? noData : loader
">
    <table class="table table-hover">
      <thead>
        <tr class="sticky-header">
          <th>Case Title
          </th>
          <th>Status</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Billable Type</th>
          <th style="width: 180px;">{{ "Budget Spent" | translate }}</th>
          <th>{{ "Est. Hours" | translate }}</th>
          <th>{{ "Billable Amount" | translate }}</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let case of cases">
          <tr>
            <td class="leadname-td">
              <div class="d-flex align-items-center leadname-container">
                <div class="leadname ellipsis" style="max-width: 200px;" pTooltip="{{case.caseName}}">{{ case.caseName }}</div>
              </div>
            </td>
            <td><span [ngClass]="case.active ? 'badge badge-info' : 'badge badge-secondary'">{{
                case.active ? 'Current' :
                'Closed' }}</span></td>
            <td>{{ case.startDate | date }}</td>
            <td>{{ case.endDate | date }}</td>
            <td>
              <span>{{case.billableType }}</span>
            </td>
            <td>
              <div class="progress">
                <div class="progress-bar">
                  <div class="progress-fill"
                    [style.width.%]="case.totalBudgetSpentInPercentage > 0?case.totalBudgetSpentInPercentage : 0"
                    [class]="getProgressBarClass(case.totalBudgetSpentInPercentage)"
                    [attr.aria-valuenow]="case.totalBudgetSpentInPercentage" aria-valuemin="0" aria-valuemax="100">
                  </div>
                  <div class="progress-text">{{ hasPermission() ? (case.totalBudgetSpentInPercentage | number:'1.2-2') : 0 }}%</div>
                </div>
              </div>

              <!-- <div class="progress">
                <div class="progress-bar" role="progressbar"
                  [style.width.%]="case.totalBudgetSpentInPercentage > 0?case.totalBudgetSpentInPercentage : 0"
                  [class]="getProgressBarClass(case.totalBudgetSpentInPercentage)"
                  [attr.aria-valuenow]="case.totalBudgetSpentInPercentage" aria-valuemin="0" aria-valuemax="100">
                  {{case.totalBudgetSpentInPercentage | number:'1.2-2'}} %
                </div>
              </div> -->
            </td>
            <td>
              <div style="max-width: 85px; font-weight: 500;">
                {{ hasPermission() ? (case.estHours) : 0 }} H</div>
            </td>
            <td>
              <div style="max-width: 85px; font-weight: 500;">{{ hasPermission() ? (case.billableAmount| currency: 'Rs ') : 0 }} </div>
            </td>
            <td>
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle btn-sm table-action-menu-btn" style="height: 25px;"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ "Options" | translate }}
                </button>
                <div class="dropdown-menu">
                  <button class="dropdown-item table-action-btn" (click)="manageAssignee(case)"  [hasPermission]="{ access: 'manage-assignee', action: 'hide' }">
                    <i class="fa-regular fa-user"></i>
                    {{"Manage Assignees" | translate}}</button>
                  <button class="dropdown-item table-action-btn" [hasPermission]="{ access: 'billing-settings', action: 'hide' }" (click)="addBillingSettings(case.id)"><i
                      class="fa-light fa-money-bill-1"></i>
                    {{"Change Billing Settings" | translate}}</button>
                  <button class="dropdown-item table-action-btn" [hasPermission]="{ access: 'addUpdateCase', action: 'disable' }" (click)="updateCase(case)"><i
                      class="fa-regular fa-envelope-open-text"></i>
                    {{"Edit Case Information" | translate}}</button>
                  <button class="dropdown-item table-action-btn" (click)="addBulkTasks(case)"><i
                      class="fa-regular fa-tasks"></i>
                    {{"Create Tasks from Template" | translate}}</button>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <ng-template #loader>
    <app-project-loading-indicator />
  </ng-template>

  <ng-template #noData>
    <app-nodatafound message="No case is created." height="calc(100vh - 340px)"></app-nodatafound>
  </ng-template>

</div>
